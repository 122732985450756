import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  addDoc,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, storage } from "../components/firebase/firebase";
import { useNavigate } from "react-router-dom";
import Loader from "../components/common/Loader";
import Alert from "../components/common/Alert";

const Admin = () => {
  const [cards, setCards] = useState({});
  const [degree, setDegree] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [referenceLink, setReferenceLink] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  useEffect(() => {
    const getPricingData = async () => {
      setLoading(true);
      const pricingRef = collection(db, "pricing");
      const pricingSnap = await getDocs(pricingRef);
      let data = pricingSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const order = [
        "10th12th-mon",
        "10th12th-half",
        "10th12th-ann",
        "jee-mon",
        "jee-half",
        "jee-ann",
        "neet-mon",
        "neet-half",
        "neet-ann",
      ];
      data = data.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));

      const groupedData = data.reduce((acc, curr) => {
        const category = curr.id.split("-")[0];
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(curr);
        return acc;
      }, {});

      setCards(groupedData);
      setLoading(false);
    };

    getPricingData();
  }, []);

  useEffect(() => {
    if (
      degree &&
      year &&
      month &&
      title &&
      ((degree !== "12thCbse" &&
        degree !== "12thState" &&
        degree !== "11thCbse" &&
        degree !== "11thState" &&
        degree !== "10thCbse" &&
        degree !== "10thState" &&
        degree !== "9thCbse" &&
        degree !== "9thState" &&
        degree !== "8thCbse" &&
        degree !== "8thState" &&
        degree !== "7thCbse" &&
        degree !== "7thState" &&
        degree !== "6thCbse" &&
        degree !== "6thState") ||
        subject)
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [degree, year, month, title, subject]);

  const handleInputChange = (category, index, event) => {
    const newCards = { ...cards };
    newCards[category][index][Object.keys(newCards[category][index])[1]] =
      event.target.value;
    setCards(newCards);
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      for (const category of Object.keys(cards)) {
        for (const card of cards[category]) {
          const docRef = doc(db, "pricing", card.id);
          await setDoc(docRef, card, { merge: true });
        }
      }
      setAlertMessage("Pricing updated successfully");
      setAlertType("success");
      setShowAlert(true);
      setLoading(false);
    } catch (error) {
      setAlertMessage("Error updating pricing");
      setAlertType("error");
      setShowAlert(true);
      setLoading(false);
      return;
    }
  };

  const handleFileUpload = async (e) => {
    setLoading(true);
    try {
      const uploadedFile = e.target.files[0];
      if (!uploadedFile) return;

      const timestamp = Date.now();
      const fileName = `${uploadedFile.name.split(".")[0]}_${timestamp}.${
        uploadedFile.name.split(".")[1]
      }`;

      setFile(uploadedFile);

      const storageRef = ref(storage, `${degree}/${fileName}`);
      await uploadBytes(storageRef, uploadedFile);
      const fileURL = await getDownloadURL(storageRef);

      let thumbnailURL = "";
      if (thumbnail) {
        const thumbnailName = `${thumbnail.name.split(".")[0]}_${timestamp}.${
          thumbnail.name.split(".")[1]
        }`;
        const thumbnailRef = ref(storage, `thumbnail/${thumbnailName}`);
        await uploadBytes(thumbnailRef, thumbnail);
        thumbnailURL = await getDownloadURL(thumbnailRef);
      }

      const resourceData = {
        link: fileURL,
        thumbnail: thumbnailURL,
        referenceLink: referenceLink,
        title: title,
        type: getTypeFromDegree(degree),
        grade: getGradeFromDegree(degree),
        subject: subject || "all",
        month: month,
        year: year,
      };

      await addDoc(collection(db, "resources"), resourceData);
      setAlertMessage("File uploaded successfully");
      setAlertType("success");
      setShowAlert(true);
    } catch (error) {
      setAlertMessage("Error uploading file");
      setAlertType("error");
      setShowAlert(true);
    } finally {
      setDegree("");
      setYear("");
      setMonth("");
      setTitle("");
      setSubject("");
      setFile(null);
      setThumbnail(null);
      setReferenceLink("");
      setLoading(false);
    }
  };


  const getTypeFromDegree = (degree) => {
    switch (degree) {
      case "10thCbse":
      case "11thCbse":
      case "12thCbse":
      case "9thCbse":
      case "8thCbse":
      case "7thCbse":
      case "6thCbse":
        return "cbse";
      case "10thState":
      case "11thState":
      case "12thState":
      case "9thState":
      case "8thState":
      case "7thState":
      case "6thState":
        return "state";
      case "jee":
        return "jee";
      case "neet":
        return "neet";
      case "tnpsc":
        return "tnpsc";
      default:
        return "";
    }
  };

  const getGradeFromDegree = (degree) => {
    switch (degree) {
      case "12thCbse":
      case "12thState":
        return "12";
      case "11thCbse":
      case "11thState":
        return "11";
      case "10thCbse":
      case "10thState":
        return "10";
      case "9thCbse":
      case "9thState":
        return "9";
      case "8thCbse":
      case "8thState":
        return "8";
      case "7thCbse":
      case "7thState":
        return "7";
      case "6thCbse":
      case "6thState":
        return "6";
      case "jee":
      case "neet":
      case "tnpsc":
        return "previous-years";
      default:
        return "";
    }
  };

  const categoryNames = {
    "10th12th": "10 & 12th CBSE/State",
    jee: "JEE",
    neet: "NEET",
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1990; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const fetchPDFs = async () => {
      setLoading(true);
      const pdfsRef = collection(db, "resources");
      const pdfsSnapshot = await getDocs(pdfsRef);
      const pdfsList = pdfsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPdfs(pdfsList);
      setLoading(false);
    };

    fetchPDFs();
  }, []);

  async function handlePDFDelete(pdfId, pdfLink, thumbnailLink) {
    setLoading(true);
    try {
      await deleteObject(ref(storage, pdfLink));

      if (thumbnailLink) {
        await deleteObject(ref(storage, thumbnailLink));
      }
      await deleteDoc(doc(db, "resources", pdfId));

      setPdfs((prevPdfs) => prevPdfs.filter((pdf) => pdf.id !== pdfId));
      setAlertMessage("PDF deleted successfully");
      setAlertType("success");
      setShowAlert(true);
    } catch (error) {
      console.error("Error deleting PDF:", error);
      setAlertMessage("Error deleting PDF");
      setAlertType("error");
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  }

  const subjects = [
    "Mathematics",
    "Science",
    "Social Science",
    "English",
    "தமிழ்",
    "Business Maths",
    "Physics",
    "Chemistry",
    "Biology",
    "Computer Science",
    "Accountancy",
    "Commerce",
    "Economics",
    "History",
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-purple-400 p-8 flex flex-col items-center justify-center">
      {showAlert && <Alert type={alertType} message={alertMessage} />}
      <div className="absolute top-20 right-8">
        <button
          onClick={handleLogout}
          className="relative px-5 py-3 overflow-hidden font-medium text-blue-600 bg-blue-100 rounded-full shadow-inner group"
        >
          <span className="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-blue-600 group-hover:w-full ease"></span>
          <span className="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-blue-600 group-hover:w-full ease"></span>
          <span className="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-blue-600 group-hover:h-full ease"></span>
          <span className="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-blue-600 group-hover:h-full ease"></span>
          <span className="absolute inset-0 w-full h-full duration-300 delay-300 bg-blue-900 opacity-0 group-hover:opacity-100"></span>
          <span className="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
            Logout
          </span>
        </button>
      </div>
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-4xl">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="grid grid-cols-1 gap-8">
              <div className="p-4 border-2 border-indigo-500 rounded-xl">
                <h2 className="text-2xl font-bold mb-4 text-center">
                  Pricing Section
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                  {Object.keys(cards).map((category, index) => (
                    <div key={index} className="flex flex-col gap-4">
                      <h3 className="text-lg text-blue-500 font-semibold bg-gray-200 px-3.5 py-1.5 rounded">
                        {categoryNames[category] || category}
                      </h3>
                      {cards[category].map((card, idx) => (
                        <input
                          key={idx}
                          type="text"
                          value={card[Object.keys(card)[1]]}
                          onChange={(e) => handleInputChange(category, idx, e)}
                          className="p-2 border border-gray-300 rounded"
                        />
                      ))}
                    </div>
                  ))}
                </div>
                <div className="flex justify-center mt-6">
                  <button
                    onClick={handleUpdate}
                    className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
                  >
                    Update
                  </button>
                </div>
              </div>
              <div className="p-4 border-2 border-indigo-500 rounded-xl">
                <h2 className="text-2xl font-bold mb-4 text-center">
                  Upload PDF
                </h2>
                <div className="flex flex-col gap-4">
                  <div className="grid grid-cols-3 gap-4">
                    <select
                      value={degree}
                      onChange={(e) => setDegree(e.target.value)}
                      className="p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select Degree</option>
                      <option value="12thCbse">12th CBSE</option>
                      <option value="11thCbse">11th CBSE</option>
                      <option value="10thCbse">10th CBSE</option>
                      <option value="9thCbse">9th CBSE</option>
                      <option value="8thCbse">8th CBSE</option>
                      <option value="8thState">8th State</option>
                      <option value="7thCbse">7th CBSE</option>
                      <option value="6thCbse">6th CBSE</option>
                      <option value="12thState">12th State</option>
                      <option value="11thState">11th State</option>
                      <option value="10thState">10th State</option>
                      <option value="9thState">9th State</option>
                      <option value="7thState">7th State</option>
                      <option value="6thState">6th State</option>
                      <option value="jee">JEE</option>
                      <option value="neet">NEET</option>
                      <option value="tnpsc">TNPSC</option>
                    </select>
                    <select
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      className="p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select Year</option>
                      {getYears().map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <select
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                      className="p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select Month</option>
                      {months.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                  <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="p-2 border border-gray-300 rounded"
                  />
                  {(degree === "12thCbse" ||
                    degree === "11thCbse" ||
                    degree === "10thCbse" ||
                    degree === "9thCbse" ||
                    degree === "8thCbse" ||
                    degree === "7thCbse" ||
                    degree === "6thCbse" ||
                    degree === "12thState" ||
                    degree === "11thState" ||
                    degree === "10thState" ||
                    degree === "9thState" ||
                    degree === "8thState" ||
                    degree === "7thState" ||
                    degree === "6thState") && (
                    <select
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select Subject</option>
                      {subjects.map((subject, index) => (
                        <option key={index} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </select>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setThumbnail(e.target.files[0])}
                    className="p-2 border border-gray-300 rounded"
                  />
                  <input
                    type="text"
                    placeholder="Reference Link"
                    value={referenceLink}
                    onChange={(e) => setReferenceLink(e.target.value)}
                    className="p-2 border border-gray-300 rounded"
                  />
                  <div className="flex flex-col items-center justify-center border-4 border-dashed border-gray-300 rounded-lg p-4 h-64">
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={handleFileUpload}
                      className="hidden"
                      id="fileUpload"
                      disabled={isButtonDisabled}
                    />
                    <label
                      htmlFor="fileUpload"
                      className={`cursor-pointer py-2 px-4 rounded-lg transition-colors ${
                        isButtonDisabled
                          ? "bg-gray-300"
                          : "bg-blue-500 text-white hover:bg-blue-400"
                      }`}
                    >
                      {file
                        ? "File Selected: " + file.name
                        : "Choose a PDF File"}
                    </label>
                    <p className="mt-4 text-gray-500 text-center">
                      Drag and drop a PDF file here or click to select a file
                      from your computer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 mt-8 border-2 border-indigo-500 rounded-xl">
              <h2 className="text-2xl font-bold mb-4 text-center">
                Uploaded PDFs
              </h2>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                {pdfs.map((pdf) => (
                  <div
                    key={pdf.id}
                    className="flex flex-col gap-2 border p-4 rounded"
                  >
                    <p>{pdf.title}</p>
                    <img
                      src={pdf.thumbnail}
                      alt="Thumbnail"
                      className="w-full h-32 object-cover"
                    />
                    <a
                      href={pdf.referenceLink}
                      className="text-blue-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Reference Link
                    </a>
                    <button
                      onClick={() =>
                        handlePDFDelete(pdf.id, pdf.link, pdf.thumbnail)
                      }
                      className="px-2 py-1 mt-2 text-white bg-red-500 rounded hover:bg-red-700"
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Admin;
