import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../components/firebase/firebase";
import { useNavigate } from "react-router-dom";
import Alert from "../components/common/Alert";
import Loader from "../components/common/Loader";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const handleLogin = (event) => {
    setLoading(true);
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        user.getIdToken().then((accessToken) => {
          localStorage.setItem("accessToken", accessToken);
        });
        setLoading(false);
        navigate("/admin");
      })
      .catch((error) => {
        const errorMessage = error.message;
        setAlertMessage(errorMessage);
        setAlertType("error");
        setShowAlert(true);
        setLoading(false);
      });
  };
  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-blue-500 to-purple-400">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-[25rem]">
        {loading && <Loader />}
        <h4 className="font-bold text-3xl mb-8 text-center">LOGIN</h4>
        {showAlert && <Alert message={alertMessage} type={alertType} />}
        <form onSubmit={handleLogin}>
          <div className="mb-8 shadow-md rounded-2xl p-4">
            <input
              type="text"
              id="email"
              name="email"
              placeholder="email"
              className="w-full border-none text-lg focus:outline-none"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
          <div className="mb-8 shadow-md rounded-2xl p-4">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="password"
              className="w-full border-none text-lg focus:outline-none"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </div>
          <div className="flex justify-center">
            <input
              type="submit"
              value="LOGIN"
              className="mt-4 w-40 h-14 rounded-full bg-blue-400 text-white text-lg font-medium shadow-lg cursor-pointer transition-all hover:bg-blue-300"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
