import { React, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FiBatteryCharging, FiWifi } from "react-icons/fi";
import { useVisitorCount } from "../components/common/useVisitorCount";
import Loader from "../components/common/Loader";

const Contact = () => {
  const [animationStarted, setAnimationStarted] = useState(false);
  const visitCount = useVisitorCount();
  const { ref, inView } = useInView({
    threshold: 0.35, // Trigger animation when at least 50% of the component is visible
    triggerOnce: true, // Only trigger once when component enters viewport
  });

  if (inView && !animationStarted) {
    setAnimationStarted(true);
  }

  if (!visitCount) {
    return <Loader />;
  }
  return (
    <section className="bg-gray-700">
      <div className="container px-6 py-12 mx-auto">
        <div className="text-center ">
          <h1 className="font-semibold text-4xl text-blue-400 capitalize my-6">
            Contact us
          </h1>

          <p className="mt-6 text-gray-400">
            Chat to our friendly team. We are always ready to help by providing
            the best services for you.
          </p>
          <div id="contact" className="mb-16">
            <div
              ref={ref}
              className="p-6 w-full flex justify-center items-center gap-x-12 gap-y-8"
            >
              {/* left side */}
              <motion.div
                className="flex flex-col justify-center items-start space-y-2"
                initial={{ x: "-8rem", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  duration: 1.75,
                  type: "spring",
                  damping: 20,
                  delay: 0.15,
                }}
              >
                <div className="flex flex-col gap-6">
                  <FloatingPhone />
                </div>
              </motion.div>

              {/* right side */}
              <div className="w-full">
                <div className="h-96">
                  <iframe
                    title="XPO hr"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7771.643974822825!2d80.213509!3d13.110461!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52655b4cabeecd%3A0xf9bb1af6542575ea!2sRAVI%20MATHS%20TUITION%20CENTER!5e0!3m2!1sen!2sin!4v1719778032659!5m2!1sen!2sin%22%20width=%22800%22%20height=%22600%22%20style=%22border:0;%22%20allowfullscreen=%22%22%20loading=%22lazy%22%20referrerpolicy=%22no-referrer-when-downgrade%22"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center"></div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-3">
          <div className="p-4 rounded-lg md:p-6 bg-gray-800">
            <span className="inline-block p-3 text-blue-500 rounded-lg bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </span>

            <h2 className="mt-4 text-base font-medium text-white">
              Connect With Us
            </h2>
            <p className="mt-2 text-sm text-blue-400">
              ravimathstuitioncenter@gmail.com
            </p>
          </div>

          <div className="p-4 rounded-lg md:p-6 bg-gray-800">
            <span className="inline-block p-3 text-blue-500 rounded-lg bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
            </span>

            <h2 className="mt-4 text-base font-medium text-white">Visit us</h2>
            <p className="mt-2 text-sm text-blue-400">
              15/7, KUMARAN NAGAR WEST EXTENTION, near V6 POLICE STATION, near
              VILLIVAKKAM, Chennai, Tamil Nadu 600082
            </p>
          </div>

          <div className="p-4 rounded-lg md:p-6 bg-gray-800">
            <span className="inline-block p-3 text-blue-500 rounded-lg bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
            </span>

            <h2 className="mt-4 text-base font-medium text-white">Call us</h2>
            <p className="mt-2 text-sm text-blue-400">+91 8056206308</p>
          </div>
        </div>
      </div>
      <div className="py-10">
        <h1 className="text-center text-3xl font-semibold text-blue-500">
          Visitor <span className="text-white">count</span>
        </h1>
        <h1 className="text-center text-2xl font-semibold text-blue-400">
          {visitCount}
        </h1>
      </div>
    </section>
  );
};

export default Contact;

const FloatingPhone = () => {
  return (
    <section className="grid place-content-center">
      <div
        style={{
          transformStyle: "preserve-3d",
          transform: "rotateY(-25deg) rotateX(15deg)",
        }}
        className="rounded-[24px] bg-blue-500"
      >
        <motion.div
          initial={{
            transform: "translateZ(8px) translateY(-2px)",
          }}
          animate={{
            transform: "translateZ(32px) translateY(-8px)",
          }}
          transition={{
            repeat: Infinity,
            repeatType: "mirror",
            duration: 2,
            ease: "easeInOut",
          }}
          className="relative h-96 w-56 rounded-[24px] border-2 border-b-4 border-r-4 border-white border-l-neutral-200 border-t-neutral-200 bg-neutral-900 p-1 pl-[3px] pt-[3px]"
        >
          <HeaderBar />
          <Screen />
        </motion.div>
      </div>
    </section>
  );
};

const HeaderBar = () => {
  return (
    <>
      <div className="absolute left-[50%] top-2.5 z-10 h-2 w-16 -translate-x-[50%] rounded-md bg-neutral-900"></div>
      <div className="absolute right-3 top-2 z-10 flex gap-2">
        <FiWifi className="text-neutral-600" />
        <FiBatteryCharging className="text-neutral-600" />
      </div>
    </>
  );
};

const Screen = () => {
  return (
    <div className="relative z-0 grid h-full w-full place-content-center overflow-hidden rounded-[20px] bg-white">
      <div className="max-w-xs flex flex-col">RAVI SIR</div>

      <button className=" cursor-pointer absolute bottom-4 left-4 right-4 z-10 rounded-lg border-[1px] bg-white py-2 text-[0.6rem] font-medium text-blue-500 backdrop-blur">
        <a className="cursor-pointer" href="tel:+91 8056206308">
          Call Now
        </a>
      </button>
      <div className="absolute -bottom-72 left-[50%] h-96 w-96 -translate-x-[50%] rounded-full bg-blue-500" />
    </div>
  );
};

export { FloatingPhone };
