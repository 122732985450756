import React, { useState, useEffect, useCallback } from "react";
import { FiArrowRight, FiChevronDown } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";

const SubMenu = () => {
  return (
    <div>
      <div className="max-wl mx-auto mb-4">
        <nav className="border-gray-200">
          <div className="container mx-auto flex flex-wrap items-center lg:justify-between justify-start">
            <div className="md:block w-full md:w-auto" id="mobile-menu">
              <ul className="flex md:flex-col md:justify-start mt-4 md:mt-0 md:text-lg md:font-medium gap-4 md:my-4">
                <div className="flex md:text-lg md:font-medium gap-4">
                  <ShiftingDropDown />
                </div>
                <div className="flex ml-1 md:text-lg md:font-medium gap-4">
                  <li>
                    <NavLink
                      to="/resources/tnpsc/previous-years"
                      className="text-sm lg:text-lg text-black hover:bg-gray-50 flex items-center gap-1 px-3 py-1.5 rounded-full"
                    >
                      TNPSC
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/resources/neet/previous-years"
                      className="text-sm lg:text-lg text-black hover:bg-gray-50 flex items-center gap-1 px-3 py-1.5 rounded-full"
                    >
                      NEET
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/resources/jee/previous-years"
                      className="text-sm lg:text-lg text-black hover:bg-gray-50 flex items-center gap-1 px-3 py-1.5 rounded-full"
                    >
                      JEE
                    </NavLink>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SubMenu;

export const ShiftingDropDown = () => {
  return (
    <div className="flex w-full justify-start text-black md:justify-center">
      <Tabs />
    </div>
  );
};

const Tabs = () => {
  const [selected, setSelected] = useState(null);
  const [dir, setDir] = useState(null);

  const handleSetSelected = (val) => {
    if (typeof selected === "number" && typeof val === "number") {
      setDir(selected > val ? "r" : "l");
    } else if (val === null) {
      setDir(null);
    }

    setSelected(val);
  };

  return (
    <div
      onMouseLeave={() => handleSetSelected(null)}
      className="relative flex h-fit gap-2"
    >
      {TABS.map((t) => {
        return (
          <Tab
            key={t.id}
            selected={selected}
            handleSetSelected={handleSetSelected}
            tab={t.id}
          >
            {t.title}
          </Tab>
        );
      })}

      <AnimatePresence>
        {selected && <Content dir={dir} selected={selected} />}
      </AnimatePresence>
    </div>
  );
};

const Tab = ({ children, tab, handleSetSelected, selected }) => {
  return (
    <button
      id={`shift-tab-${tab}`}
      onMouseEnter={() => handleSetSelected(tab)}
      onClick={() => handleSetSelected(tab)}
      className={`flex items-center gap-1 rounded-full px-3 py-1.5 text-lg transition-colors ${
        selected === tab ? " bg-white text-black" : "text-black"
      }`}
    >
      <span className="text-sm lg:text-lg">{children}</span>
      <FiChevronDown
        className={`transition-transform ${
          selected === tab ? "rotate-180" : ""
        }`}
      />
    </button>
  );
};

const Content = ({ selected, dir }) => {
  return (
    <motion.div
      id="overlay-content"
      initial={{
        opacity: 0,
        y: 8,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{
        opacity: 0,
        y: 8,
      }}
      className="absolute left-0 top-[calc(100%_+_24px)] w-[13.5rem] rounded-lg border border-neutral-600 bg-gradient-to-b from-neutral-900 via-neutral-900 to-neutral-800 p-4"
    >
      <Bridge />
      <Nub selected={selected} />

      {TABS.map((t) => {
        return (
          <div className="overflow-hidden" key={t.id}>
            {selected === t.id && (
              <motion.div
                initial={{
                  opacity: 0,
                  x: dir === "l" ? 100 : dir === "r" ? -100 : 0,
                }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.25, ease: "easeInOut" }}
              >
                <t.Component />
              </motion.div>
            )}
          </div>
        );
      })}
    </motion.div>
  );
};

const Bridge = () => (
  <div className="absolute -top-[24px] left-0 right-0 h-[24px]" />
);

const Nub = ({ selected }) => {
  const [left, setLeft] = useState(0);

  const moveNub = useCallback(() => {
    if (selected) {
      const hoveredTab = document.getElementById(`shift-tab-${selected}`);
      const overlayContent = document.getElementById("overlay-content");

      if (!hoveredTab || !overlayContent) return;

      const tabRect = hoveredTab.getBoundingClientRect();
      const { left: contentLeft } = overlayContent.getBoundingClientRect();

      const tabCenter = tabRect.left + tabRect.width / 2 - contentLeft;

      setLeft(tabCenter);
    }
  }, [selected]);

  useEffect(() => {
    moveNub();
  }, [moveNub]);

  return (
    <motion.span
      style={{
        clipPath: "polygon(0 0, 100% 0, 50% 50%, 0% 100%)",
      }}
      animate={{ left }}
      transition={{ duration: 0.25, ease: "easeInOut" }}
      className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 rounded-tl border border-neutral-600 bg-neutral-900"
    />
  );
};

const NavDropCBSE = () => {
  return (
    <div>
      <div className="flex gap-4">
        <div className="mx-8">
          <NavLink
            to="/resources/cbse/12"
            className="mb-1 block text-lg text-white"
          >
            12th Grade
          </NavLink>
          <NavLink
            to="/resources/cbse/11"
            className="mb-1 block text-lg text-white"
          >
            11th Grade
          </NavLink>
          <NavLink
            to="/resources/cbse/10"
            className="mb-1 block text-lg text-white"
          >
            10th Grade
          </NavLink>
          <NavLink
            to="/resources/cbse/9"
            className="mb-1 block text-lg text-white"
          >
            9th Grade
          </NavLink>
          <NavLink
            to="/resources/cbse/8"
            className="mb-1 block text-lg text-white"
          >
            8th Grade
          </NavLink>
        </div>
      </div>

      <button
        className="ml-auto mt-4 flex items-center gap-1 text-lg text-blue-400"
        onClick={() => {
          window.location.href = "/practice";
        }}
      >
        <span>View more</span>
        <FiArrowRight />
      </button>
    </div>
  );
};

const NavDropSTATE = () => {
  return (
    <div>
      <div className="flex gap-4">
        <div className="mx-8">
          <NavLink
            to="/resources/state/12"
            className="mb-1 block text-lg text-white"
          >
            12th Grade
          </NavLink>
          <NavLink
            to="/resources/state/11"
            className="mb-1 block text-lg text-white"
          >
            11th Grade
          </NavLink>
          <NavLink
            to="/resources/state/10"
            className="mb-1 block text-lg text-white"
          >
            10th Grade
          </NavLink>
          <NavLink
            to="/resources/state/9"
            className="mb-1 block text-lg text-white"
          >
            9th Grade
          </NavLink>
          <NavLink
            to="/resources/state/8"
            className="mb-1 block text-lg text-white"
          >
            8th Grade
          </NavLink>
        </div>
      </div>

      <button
        className="ml-auto mt-4 flex items-center gap-1 text-lg text-blue-400"
        onClick={() => {
          window.location.href = "/practice";
        }}
      >
        <span>View more</span>
        <FiArrowRight />
      </button>
    </div>
  );
};

const TABS = [
  {
    id: "cbse",
    title: "CBSE",
    Component: NavDropCBSE,
  },
  {
    id: "state",
    title: "STATE",
    Component: NavDropSTATE,
  },
];
