import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../components/firebase/firebase";

const SubjectPapers = () => {
  const { type, grade, subject } = useParams();
  const [filteredPapers, setFilteredPapers] = useState([]);

  useEffect(() => {
    const fetchPapers = async () => {
      const papersRef = collection(db, "resources");
      let q = query(papersRef, where("type", "==", type));

      // Filter by grade if not "all"
      if (grade !== "all") {
        q = query(q, where("grade", "==", grade));
      }

      const querySnapshot = await getDocs(q);
      const papersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFilteredPapers(papersData);
    };

    fetchPapers();
  }, [type, grade, subject]);

  const getCoverImage = (paper) => {
    if (paper.thumbnail) {
      return paper.thumbnail; // Use the thumbnail if it exists
    } else {
      return "";
    }
  };

  return (
    <section className="courses bg-[#F3F4F8] py-16">
      <div className="w-4/5 m-auto">
        <div className="heading mb-16">
          <h1 className="text-3xl font-semibold text-black">
            {type.toUpperCase()} {grade !== "all" && `${grade}`}{" "}
            {subject !== "all" && subject} Practice Papers
          </h1>
          <span className="text-sm mt-2 block">
            Work on your skills and improve your knowledge with our free papers.
          </span>
        </div>
        <div className="grid grid-cols-3 gap-8 md:grid-cols-1">
          {filteredPapers.map((paper, index) => (
            <motion.div
              key={index}
              className="box rounded-lg shadow-shadow1 bg-white"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="images rounded-t-lg relative overflow-hidden h-40 w-full">
                <img
                  src={getCoverImage(paper)}
                  alt=""
                  className="rounded-t-lg object-cover w-full h-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300"
                />
                <div className="categ flex gap-4 absolute top-0 m-3">
                  <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                    {paper.subject}
                  </span>
                  <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                    {paper.month} {paper.year}
                  </span>
                </div>
              </div>
              <div className="text p-3">
                <h3 className="text-black my-4 font-medium h-10">
                  {paper.title}
                </h3>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 p-3">
                {paper.referenceLink && (
                  <a
                    href={paper.referenceLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[14px] text-primary"
                  >
                    Reference
                  </a>
                )}
                <NavLink
                  to={`${paper.link}`}
                  className="text-[14px] ml-2 flex items-center hover:text-primary"
                >
                  View Paper <HiOutlineArrowNarrowRight />
                </NavLink>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SubjectPapers;
