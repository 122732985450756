import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Testimonials from "../components/common/Testimonials";

export const WhyUs = () => {
  return (
    <>
      <section className="mb-4 mx-4">
        <div className="heading py-12 text-center w-2/3 m-auto md:w-full">
          <h3 className="text-center text-5xl my-4 font-bold text-black">
            Why
            <motion.span
              initial={{ color: "#000" }}
              animate={{ color: "#2D69F0" }}
              transition={{ duration: 1 }}
            >
              &nbsp;Choose&nbsp;
            </motion.span>{" "}
            Us
          </h3>
        </div>
        <TextParallaxCard />
        <Testimonials />
      </section>
    </>
  );
};

export const TextParallaxCard = () => {
  return (
    <motion.div className="bg-white"
    initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}>
      <TextParallaxContent
        imgUrl="/Expertise.jpg"
        heading="Expertise"
        content="Our team of experienced educators brings a wealth of knowledge and expertise in academic requirements and exam patterns. With years of teaching and curriculum development experience, they understand the nuances of the education system and what it takes for students to succeed. 
        Their guidance ensures that every resource we provide is aligned with the latest educational standards and tailored to meet the needs of diverse learners."
      />
      <TextParallaxContent
        imgUrl="/Quality.jpg"
        heading="Quality"
        content="At Ravi Test Papers, quality is our top priority. We meticulously craft each question paper to reflect the most recent syllabus and exam trends, ensuring that students receive the most relevant and up-to-date materials. Our rigorous quality control process guarantees that every question is accurate, clear, and aligned with educational standards, 
        providing students with the best possible preparation for their exams."
      />
      <TextParallaxContent
        imgUrl="/Convenience.jpg"
        heading="Convenience"
        content="Our platform is designed with your convenience in mind. Easily purchase and download the educational materials you need with just a few clicks. To enhance your user experience, our website seamlessly redirects you to WhatsApp, where you can complete your transactions and get instant access to your resources. 
        This streamlined process ensures that you can focus on your studies without any hassle."
      />
      <TextParallaxContent
        imgUrl="/Success.jpg"
        heading="Success-Oriented"
        content="We are committed to your success. Our carefully curated resources are designed to boost your confidence and improve your exam preparation. By using our materials, you will be well-equipped to achieve better results and reach your academic goals. 
        Our focus is on providing you with the tools and support you need to excel in your studies and beyond."
      />
    </motion.div>
  );
};

const IMG_PADDING = 12;

const TextParallaxContent = ({ imgUrl, content, heading, children }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { content: content });
    }
    return child;
  });

  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} content={content} />
      </div>
      {childrenWithProps}
    </div>
  );
};
const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ heading, content }) => {
  const navigate = useNavigate();

  const Navigateto = () => {
    navigate("/pricing");
  };
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="text-center sm:text-4xl font-bold text-7xl">{heading}</p>
      <div className="col-span-1 md:col-span-8">
        <p className="text-neutral-300 lg:text-2xl md:text-base my-12 lg:mx-28 mx-4 leading-loose">
          {content}
        </p>
        <div className="flex justify-center">
          <button
            className="w-1/2 items-center rounded-2xl bg-neutral-900 px-9 py-4 text-lg text-white transition-colors hover:bg-neutral-700 md:w-fit"
            onClick={Navigateto}
          >
            Learn more <FiArrowUpRight className="inline" />
          </button>
        </div>
      </div>
    </motion.div>
  );
};
