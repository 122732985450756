import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Testimonials = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.2 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <h2 className="w-full text-4xl font-bold md:text-5xl text-gray-400 text-center mb-16">
          What Others
          <span className="text-blue-500"> Has to Say</span>
        </h2>
        <motion.div
          ref={ref}
          className="grid sm:grid-cols-1 lg:grid-cols-3 gap-6"
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
        >
          {[
            {
              text: `" In this className, you sent many documents in the whatapp group
                  and also upload videos in your YouTube channel Ravi maths
                  tuition centre channel. "`,
              name: "Surya R",
              time: "a year ago",
            },
            {
              text: `" I run my centre and i use your qps only for my students .
                  Expect to even include for lower grades qps too in both cbse,
                  samacheer. Would also like to have some qps based on IGCSE,
                  GCSE syllabus too. But overall till now it's 5star rating.
                  Thank u so much for your effort sir. "`,
              name: "Tabasum Hameed",
              time: "a year ago",
            },
            {
              text: `"I loved learning in this atmosphere. I was free to ask
                  doubts. I was provided with lot n lot of question papers which
                  helped me to crack in my exams. Sir was down to earth to teach
                  even the simple and basic steps, which helped me alot.Thank
                  you sir "`,
              name: "Roshini B",
              time: "a year ago",
            },
            {
              text: `" Ravi sir,is an extremely good math teacher. If there were
                  hundred stars,he deserves that! Both my children have had
                  their math tutions under his able guidance and not only have
                  they understood the concepts and performed extremely well in
                  their board exams,they have got into the best of universities
                  in India and abroad !! Above all,he has made math an exciting
                  subject for my children."`,
              name: "Shirin Mammen",
              time: "6 years ago",
            },
            {
              text: `" The best math tutor I've had, made me pass a college exam i
                  couldn't clear for 3 years, very kind and patient."`,
              name: "Rohan Sampat",
              time: "6 years ago",
            },
            {
              text: `" Your materials and videos helped lots to rural students
                  Thank you so much sir Once agin thank you sir"`,
              name: "Manoj Mano",
              time: "a year ago",
            },
          ].map((testimonial, index) => (
            <motion.div
              key={index}
              className="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl overflow-hidden"
              variants={itemVariants}
              whileHover={{
                scale: 1.05,
                rotate: 1,
                boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.2)",
              }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 20,
              }}
            >
              <div className="flex-auto p-4 md:p-6 items-center flex">
                <p className="mt-3 sm:mt-6 text-base text-gray-800 lg:text-xl">
                  <em>{testimonial.text}</em>
                </p>
              </div>
              <div className="p-4 rounded-b-xl md:px-6 bg-gray-50">
                <h3 className="text-sm font-semibold text-gray-800 sm:text-base">
                  {testimonial.name}
                </h3>
                <p className="text-sm text-gray-500">{testimonial.time}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Testimonials;
