import React from "react";

const Loader = () => {
  return (
    <div>
      <div
        className="loader-container"
        style={{
          position: "relative",
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <div className="rounded-md h-12 w-12 border-4 border-t-4 border-blue-500 animate-spin absolute"></div>
      </div>
    </div>
  );
};

export default Loader;
