import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { db } from "../components/firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import Loader from "../components/common/Loader";

const staticPlans = [
  {
    name: "MONTHLY",
    description:
      "MONTHLY subscription enables you to explore Question papers with Answers for 30 days",
    features: ["25 - 30 Question Paper", "Study Materials", "Chat support"],
  },
  {
    name: "HALF YEARLY",
    description:
      "HALF YEARLY subscription enables you to explore Question papers with Answers for 6 Months",
    features: ["200+ Question Paper", "Study Materials", "Chat support"],
  },
  {
    name: "ANNUAL",
    description:
      "ANNUAL subscription enables you to explore Question papers with Answers for 12 Months",
    features: ["500+ Question Paper", "Study Materials", "Chat support"],
  },
];

const categories = [
  { name: "10th and 12th", prefix: "10th12th" },
  { name: "JEE", prefix: "jee" },
  { name: "NEET", prefix: "neet" },
];

const PlanCard = ({ name, price, description, features }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.35 }}
      whileHover={{
        scale: 1.15,
        boxShadow: "0px 0px 8px rgba(255, 255, 255, 0.8)",
      }}
      className="px-6 py-4 bg-clip-padding bg-opacity-60 border border-opacity-20 duration-200 transform rounded-lg"
    >
      <p className="text-lg font-medium text-white dark:text-gray-100">
        {name}
      </p>
      <h4 className="mt-2 text-4xl font-semibold text-white dark:text-gray-100">
        ₹{price}
        <span className="text-base font-normal text-gray-600 dark:text-gray-400 ml-2">
          Only
        </span>
      </h4>
      <p className="mt-4 text-white dark:text-gray-300">{description}</p>
      <div className="mt-8 space-y-8">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 text-blue-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="mx-4 text-gray-400 dark:text-gray-300">
              {feature}
            </span>
          </div>
        ))}
      </div>
      <button
        className="w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        onClick={() =>
          (window.location.href = "https://wa.me/qr/DO3TUISQWWSCH1")
        }
      >
        Choose plan
      </button>
    </motion.div>
  );
};

const Pricing = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPricingData = async () => {
      try {
        const pricingRef = collection(db, "pricing");
        const pricingSnap = await getDocs(pricingRef);
        let data = pricingSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPlans(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    getPricingData();
  }, []);

  const combinePlans = () => {
    const combinedPlans = [];

    categories.forEach((category) => {
      staticPlans.forEach((staticPlan) => {
        const planType = staticPlan.name.toLowerCase().replace(" ", "");
        const pricingPlan = plans.find((plan) => {
          if (plan.id.startsWith(category.prefix)) {
            if (planType === "monthly" && plan["montly"] !== undefined) {
              return plan["montly"];
            }
            return plan[planType];
          }
          return false;
        });

        if (pricingPlan) {
          combinedPlans.push({
            ...staticPlan,
            price: pricingPlan[planType] || pricingPlan["montly"],
            category: category.name,
          });
        }
      });
    });
    return combinedPlans;
  };

  const combinedPlans = combinePlans();

  const plansByCategory = combinedPlans.reduce((acc, plan) => {
    if (!acc[plan.category]) {
      acc[plan.category] = [];
    }
    acc[plan.category].push(plan);
    return acc;
  }, {});

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <section className="courses">
        <div className="w-4/5 m-auto">
          <div className="heading text-center py-12">
            <h3 className="text-center text-5xl my-4 font-bold text-black">
              Plans
              <motion.span
                initial={{ color: "#000" }}
                animate={{ color: "#2D69F0" }}
                transition={{ duration: 1 }}
              >
                &nbsp;We&nbsp;
              </motion.span>{" "}
              Offer
            </h3>
            <span className="text-sm mt-2 block">
              Choose the best plan that suits your needs.
            </span>
          </div>
          <section className="h-full bg-cover bg-center bg-fixed bg-gray-800 px-2 rounded-2xl">
            <div className="container px-6 py-8 mx-auto">
              {Object.entries(plansByCategory).map(
                ([category, plans], index) => (
                  <div key={index} className="mb-16">
                    <h1 className="my-12 text-3xl text-center font-medium text-white dark:text-gray-100">
                      {category}
                    </h1>
                    <div className="grid gap-20 -mx-6 grid-cols-1 xl:grid-cols-3">
                      {plans.map((plan, index) => (
                        <PlanCard
                          key={index}
                          name={plan.name}
                          price={plan.price}
                          description={plan.description}
                          features={plan.features}
                        />
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Pricing;
