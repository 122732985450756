import React, { useEffect, useState } from "react";

const Alert = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`fixed top-20 min-w-[25rem] max-w-lg bg-white rounded-lg shadow-lg p-4 ${
        isVisible ? "opacity-100" : "opacity-0"
      } transition-opacity duration-500 ease-in-out z-10 border-2
      ${type === "success" ? "border-green-500" : "border-red-500"}
      `}
    >
      <div
        className={`${
          type === "success" ? "text-green-500" : "text-red-500"
        } text-lg font-semibold mb-2`}
      >
        {type === "success" ? "Operation Success !" : "Operation Failed !"}
      </div>
      <p className="text-gray-800">{message}</p>
    </div>
  );
};

export default Alert;
