export const LinkData = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About",
    url: "/about",
  },
  {
    id: 3,
    title: "Why Us",
    url: "/why-us",
  },
  {
    id: 4,
    title: "Pricing",
    url: "/pricing",
  },
  {
    id: 5,
    title: "Free Resources",
    url: "/practice",
  },
  {
    id: 6,
    title: "Contact Us",
    url: "/contact",
  },
  
]
