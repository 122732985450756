import React, { useState, useEffect } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { motion } from "framer-motion";
import { db } from "../components/firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import Loader from "../components/common/Loader";

export const Practice = () => {
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPapers = async () => {
      try {
        const papersCollection = collection(db, "resources");
        const papersSnapshot = await getDocs(papersCollection);
        const papersData = papersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPapers(papersData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchPapers();
  }, []);

  const getCoverImage = (item) => {
    if (item.thumbnail) {
      return item.thumbnail; // Use the thumbnail if it exists
    } else {
      return ""; // Return a placeholder image if thumbnail doesn't exist
    }
  };

  const groupPapers = (papers) => {
    const grouped = {};
    papers.forEach((paper) => {
      const grade = paper.grade;
      const subject = paper.subject || "all";
      const type = paper.type || "";

      if (!grouped[grade]) {
        grouped[grade] = { type: type, subjects: {} };
      }
      if (!grouped[grade].subjects[subject]) {
        grouped[grade].subjects[subject] = [];
      }
      grouped[grade].subjects[subject].push(paper);
    });

    // Sort each group by month and year
    for (const grade in grouped) {
      for (const subject in grouped[grade].subjects) {
        grouped[grade].subjects[subject].sort((a, b) => {
          const dateA = new Date(
            a.year,
            new Date(a.month + " 1, 2024").getMonth()
          );
          const dateB = new Date(
            b.year,
            new Date(b.month + " 1, 2024").getMonth()
          );
          return dateA - dateB;
        });
      }
    }

    return grouped;
  };

  if (loading) {
    return <Loader />;
  }

  const groupedPapers = groupPapers(papers);

  return (
    <section className="courses bg-[#F3F4F8] py-16">
      <div className="w-4/5 m-auto">
        <div className="heading mb-16">
          <h1 className="text-3xl font-semibold text-black">Free Practice</h1>
          <span className="text-sm mt-2 block">
            Work on your skills and improve your knowledge with our free papers.
          </span>
        </div>
        {Object.keys(groupedPapers).map((grade) => (
          <div key={grade} className="mt-6">
            <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
              {grade.toUpperCase()}{" "}
              {groupedPapers[grade].type &&
                `${groupedPapers[grade].type.toUpperCase()}`}
            </h2>
            {Object.keys(groupedPapers[grade].subjects).map((subject) => (
              <div key={subject} className="mt-4">
                {subject !== "all" && (
                  <h3 className="text-xl font-semibold text-gray-600 mb-2">
                    {subject.toUpperCase()}
                  </h3>
                )}
                <div className="grid grid-cols-3 gap-8 md:grid-cols-1">
                  {groupedPapers[grade].subjects[subject].map((item, index) => (
                    <motion.div
                      key={index}
                      className="box rounded-lg shadow-shadow1 bg-white"
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <div className="images rounded-t-lg relative overflow-hidden h-40 w-full">
                        <img
                          src={getCoverImage(item)}
                          alt=""
                          className="rounded-t-lg object-cover w-full h-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300"
                        />
                        <div className="categ flex gap-4 absolute top-0 m-3">
                          <span className="text-[14px] bg-rose-500 p-1 px-3 text-white rounded-[5px] shadow-md">
                            {item.subject}
                          </span>
                          <span className="text-[14px] bg-green-500 p-1 px-3 text-white rounded-[5px] shadow-md">
                            {item.month} {item.year}
                          </span>
                        </div>
                      </div>
                      <div className="text p-3">
                        <h3 className="text-black my-4 font-medium h-10">
                          {item.title}
                        </h3>
                      </div>
                      <div className="flex items-center justify-between border-t border-gray-200 p-3">
                        <span className="text-sm text-primary">
                          {item.referenceLink && (
                            <a
                              href={item.referenceLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-[14px] hover:text-primary"
                            >
                              Reference
                            </a>
                          )}
                        </span>
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-[14px] ml-2 flex items-center hover:text-primary"
                        >
                          View Paper <HiOutlineArrowNarrowRight />
                        </a>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};
