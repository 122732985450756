import { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import { db } from "../firebase/firebase";

export const useVisitorCount = () => {
  const [visitCount, setVisitCount] = useState(0);

  useEffect(() => {
    const incrementVisitorCount = async () => {
      const visitCountRef = doc(db, "visitCount", "count");
      const visitCountSnap = await getDoc(visitCountRef);
      if (!visitCountSnap.exists()) {
        await updateDoc(visitCountRef, { value: 1 });
      } else {
        await updateDoc(visitCountRef, {
          value: visitCountSnap.data().value + 1,
        });
        setVisitCount(visitCountSnap.data().value + 1);
      }
    };
    incrementVisitorCount();
  }, []);

  return visitCount;
};
